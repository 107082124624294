/*
This is the BASE TableExplorer.
It is a searchable list of tables that is designed to be extended via prop injection.

Components that use it should override it's click handlers and hoverRenders. 
One day someone might want to override the rowRenders as well but this is not implemented as 07/02/24.

Note that it memoizes everything it passes to the table name list objects to reduce the number of rerenders.
That component hierarchy is rather expensive.
*/
import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react';

import { X } from 'react-bootstrap-icons';
import { useMeasure } from 'react-use';

import { Resizable } from 're-resizable';

import API from 'api/API';
import { AggTable, QueryRunProps, QueryRunResults } from 'api/APITypes';
import { Column, useGetColumns } from 'api/columnAPI';
import IconButton from 'components/inputs/basic/Button/IconButton';
import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import TextInput from 'components/inputs/basic/TextInput/TextInput';
import LoadingPlaceholder from 'components/layouts/containers/LoadingPlaceholder/LoadingPlaceholder';
import DragBar from 'components/layouts/parts/DragBar/DragBar';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useDatabaseAccount } from 'context/AuthContext';
import useTrackFilter from 'hooks/useTrackFilter';
import { convertColumnName } from 'utils/dbName';
import { useSearchFocus } from 'utils/React';

import SchemaExpandoList from './expando_lists/lists/SchemaExpandoList';
import TagExpandoList from './expando_lists/lists/TagExpandoList';
import { pickHighlightFilter } from './highlightUtils';
import TableDetails, { SampleMap } from './TableDetails/TableDetails';
import { FilteredSchemaMap, SchemasExpandedMap } from './TableExplorerReducer';
import TableNameList from './TableNameList';
import { ROW_HEIGHT } from './TableRow/TableRow';
import { TableExplorerReduction } from './useTableExplorerReducer';
import ViewModeToggle from './ViewModeToggle/ViewModeToggle';

const DRAG_BAR_HEIGHT = 25;
const MIN_NAME_LIST_HEIGHT = ROW_HEIGHT;
const NO_TABLES_HEIGHT = 89;

function sampleRowsQuery(fullName: string, columns: Column[]) {
  // Column names are either already wrapped in quotes or lowercase.
  // If they are the latter, uppercase them and wrap them in quotes.
  const escapedColumns = columns.map((c) => (c.name[0] === '"' ? c.name : `"${c.name.toUpperCase()}"`));
  const subset = `with subset as (SELECT * FROM ${fullName} limit 100)`;
  const distincts = escapedColumns.map(
    (c, i) => `f${i} as (SELECT temp.${c}, row_number() over (order by ${c} desc) as "__ROW__" from (
  SELECT distinct(subset.${c}) from subset limit 5) as temp
)`,
  );
  const joinedDistincts = distincts.join(',\n');

  const colNames = escapedColumns.map((c, i) => `f${i}.${c}`);
  const joinedColNames = colNames.join(', ');
  const select = `select ${joinedColNames}\nfrom (SELECT column1 as "__ROW__" FROM (VALUES (1),(2),(3),(4),(5))) as series`;
  const joinQueries = escapedColumns.map(
    (c, i) => `full outer join f${i} on series."__ROW__" = f${i}."__ROW__"`,
  );
  const joins = joinQueries.join('\n');

  return `${subset},\n${joinedDistincts}\n${select}\n${joins}`;
}

interface TableExplorerBaseProps {
  selectedTable: AggTable | null;
  pickedTables?: Record<string, AggTable>;
  schemaPicking?: {
    pickedSchemas: Set<string>;
    pickSchema: (schema: string) => void;
    unpickSchema: (schema: string) => void;
    enabled: boolean;
  };
  tableDraggable: boolean;
  tablesNamesUsedInSql: string[];
  tableExplorerReduction: TableExplorerReduction;
  eventLocation: string;
  showPinned?: boolean;
  onlyAutoPin?: boolean;
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>;
  onDoubleClickTable(table: AggTable): void;
  onDoubleClickColumn(column: Column): void;
  renderTableHover(
    hoveredIndex: number,
    hoveredTable: AggTable,
    selectedTable: AggTable | null,
    overlayRight: number,
  ): React.ReactNode;
  renderColumnHover(column: Column, overlayRight: number): React.ReactNode;
  rightOfSearchWidget?: React.ReactNode;
  hideTabs?: boolean;
}

const TableExplorerBase = React.memo((props: TableExplorerBaseProps) => {
  const {
    selectedTable,
    pickedTables,
    schemaPicking,
    tableDraggable,
    tableExplorerReduction,
    eventLocation,
    showPinned,
    onlyAutoPin,
    setSelectedTable,
    onDoubleClickTable,
    onDoubleClickColumn,
    renderTableHover,
    renderColumnHover,
    rightOfSearchWidget,
    hideTabs = false,
  } = props;

  const {
    anyError,
    loaded,
    hasEmptyTables,
    hasViews,
    filter,
    filterIncludes,
    hideEmptyTables,
    hideViews,
    filteredTables,
    filteredSchemasMap,
    isFiltering,
    schemasExpandedMap,
    handleToggleSchema,
    filteredTagsMap,
    filteredTablesCount,
    filteredTablesInTagsCount,
    tagsExpandedMap,
    handleToggleTag,
    handleFilterChange,
    viewMode,
    setViewMode,
    handleToggleHideEmptyTables,
    handleToggleHideViews,
    tablesActuallyPinnedMap,
    tags,
    unfilteredSchemasMap,
    unfilteredFavoriteTables,
    unfilteredTagsMap,
    disabledTablesByID,
  } = tableExplorerReduction;

  const databaseType = useDatabaseAccount().type;

  // The concept of "table set" and "view mode" were mangled together in the original implementation of TableExplorer.
  // ie one `viewMode` variable determined both "table set" and "view mode".
  // As of right 08/09/2022:
  // `viewMode` is controlled by the ViewModeToggle and determines the "table set" to render.
  // `listType` determines if the tables are rendered in one `list`, grouped by `schema`, or grouped by `tag`.
  // We'll clean up these names once we pick winners for all of the experiments.
  let listType = 'list';
  if (viewMode === 'tag') {
    listType = 'tag';
  } else if (viewMode === 'schema' || viewMode === 'pinned' || viewMode === 'favorites') {
    listType = 'schema';
  }

  const [listContainerRef, { height: availableListHeight }] = useMeasure<HTMLDivElement>();
  // The initialDragListHeight needs to be dynamically calculated because the window size can change.
  // Previous layout versions tried to keep vertical alignment between the `<DragBar />` in this component
  // and the divder between the QueryEditor and the RunResults.
  // We are not worrying about that right now but may revisit that idea if this implemenation looks sloppy.
  const { initialDragListHeight, maxResizeHeight } = useMemo(() => {
    const initialDragListHeight = availableListHeight / 2;
    const maxResizeHeight = availableListHeight - DRAG_BAR_HEIGHT;
    return { initialDragListHeight, maxResizeHeight };
  }, [availableListHeight]);

  const actualFilteredTableCount = viewMode === 'tag' ? filteredTablesInTagsCount : filteredTablesCount;
  const actualFilteredTableHeight = useMemo(() => {
    const rowsForExpandoMap = (tablesMap: FilteredSchemaMap, expandedMap: SchemasExpandedMap) => {
      let rows = 0;
      Object.keys(tablesMap).forEach((key) => {
        rows += 1; // Add one for expando title.
        // If the expando is open, add the table count.
        if (expandedMap[key]) {
          rows += tablesMap[key].length;
        }
      });
      return rows;
    };

    let actualHeight = filteredTables.length * ROW_HEIGHT;
    if (listType === 'schema') {
      actualHeight = rowsForExpandoMap(filteredSchemasMap, schemasExpandedMap) * ROW_HEIGHT;
    } else if (listType === 'tag') {
      actualHeight = rowsForExpandoMap(filteredTagsMap, tagsExpandedMap) * ROW_HEIGHT;
    }
    return actualHeight;
  }, [
    listType,
    filteredTables,
    filteredSchemasMap,
    filteredTagsMap,
    schemasExpandedMap,
    tagsExpandedMap,
  ]);

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [columnDetails, setColumnDetails] = useState<Column[]>([]);
  const [columnSamples, setColumnSamples] = useState<SampleMap>({});
  const [dragListHeight, setDragListHeight] = useState(0);
  const [userSetDragListHeight, setUserSetDragListHeight] = useState(false);
  const [filterRef] = useSearchFocus();
  const reportOnBlur = useTrackFilter(eventLocation, filter);
  const zeroMatchingTablesRef = useRef<HTMLDivElement>(null);

  // The browser scroll bar height.
  // This varies from browser to browser and OS to OS.
  // So pick the biggest one.
  const SCROLL_BAR_HEIGHT = 17;

  // Adjust the size of the drag list depending on how many
  // search results there are and the size of the window.
  useEffect(() => {
    // Expand TableNameList if the viewing area is too small
    let newHeight = actualFilteredTableHeight + SCROLL_BAR_HEIGHT;
    if (actualFilteredTableCount === 0) {
      newHeight = NO_TABLES_HEIGHT;
    }
    newHeight = Math.min(newHeight, initialDragListHeight);
    if (!userSetDragListHeight && dragListHeight < newHeight) {
      setDragListHeight(newHeight);
    }
  }, [
    dragListHeight,
    userSetDragListHeight,
    initialDragListHeight,
    actualFilteredTableHeight,
    actualFilteredTableCount,
  ]);

  // Get the columns from the queryCache or the API.
  const {
    isLoading: columnsIsLoading,
    data: columns,
    error: columnsError,
  } = useGetColumns(selectedTable?.id);

  const showDetails = loaded && !anyError && selectedTable !== null;

  const filteredHeight = actualFilteredTableHeight + SCROLL_BAR_HEIGHT;

  const fetchSampleRows = useCallback(
    (table: AggTable, columns: Column[]) => {
      const sql = sampleRowsQuery(table.full_name, columns);

      const api = new API();

      const postData: QueryRunProps = {
        sql,
        limit: 100,
      };

      return api.post('api/run_sql', postData).then((response) => {
        const sampleMap: SampleMap = {};
        // On 12/05/22 we investigated and failed to figure out why response could be undefined.
        // We added the ?s as a temporary bandaid
        if (response?.data?.error_lines) {
          // Silently ignore error
        } else {
          const { columns, rows } = response.data.results as QueryRunResults;

          columns.forEach((c, i) => {
            const samples: any[] = [];
            rows.forEach((r) => {
              samples.push(r[i]);
            });
            // Escape the column name in the same way it is escaped everywhere else in the app.
            const convertedName = convertColumnName(c.name, databaseType);
            const sample = {
              id: c.id,
              name: convertedName,
              type: c.type,
              description: c.description,
              ordinal_position: c.ordinal_position,
              is_primary_key: c.is_primary_key,
              pk_to_fks: c.pk_to_fks,
              fk_to_pks: c.fk_to_pks,
              samples,
            };

            sampleMap[convertedName] = sample;
          });
        }
        return sampleMap;
      });
    },
    [databaseType],
  );

  const fetchHoverRows = useCallback(
    (table: AggTable, columns: Column[]) => {
      fetchSampleRows(table, columns).then((sampleMap) => {
        // Append column descriptions(if they exist) to sample data.
        columns.forEach((c) => {
          const sample = sampleMap[c.name];
          if (sample && c.description) {
            sample.description = c.description;
          }
        });

        setColumnSamples(sampleMap);
      });
    },
    [fetchSampleRows, setColumnSamples],
  );

  useEffect(() => {
    if (selectedTable && columns) {
      if (!userSetDragListHeight && filteredHeight < initialDragListHeight) {
        setDragListHeight(filteredHeight);
      }

      setColumnDetails(columns);
      fetchHoverRows(selectedTable, columns);
    } else {
      setColumnDetails([]);
    }
  }, [
    userSetDragListHeight,
    filteredHeight,
    initialDragListHeight,
    selectedTable,
    columns,
    setDragListHeight,
    setColumnDetails,
    fetchHoverRows,
  ]);

  const handleToggleShowAdvancedOptions = () => {
    const show = !showAdvancedOptions;
    setShowAdvancedOptions(show);
    analytics.track(
      show ? `${eventLocation} ShowAdvancedSearch` : `${eventLocation} HideAdvancedSearch`,
    );
  };

  const handleSelectTable = useCallback(
    (table: AggTable) => {
      // Don't select if already selected
      if (selectedTable?.id === table.id) {
        return;
      }
      analytics.track(`${eventLocation} SelectTable`);
      setSelectedTable(table);
    },
    [selectedTable, eventLocation, setSelectedTable],
  );

  const handleDeselectTable = () => {
    analytics.track(`${eventLocation} DeselectTable`);
    setSelectedTable(null);
    setUserSetDragListHeight(false);
  };

  const handleResize = (
    event: MouseEvent | TouchEvent,
    direction: any,
    refToElement: HTMLElement,
    delta: any,
  ) => {
    // Sentry suggests refToElement might be null
    if (refToElement) {
      setDragListHeight(refToElement.offsetHeight);
      setUserSetDragListHeight(true);
    }
  };

  const tableHighlightFilter = useMemo(
    () => pickHighlightFilter(filterIncludes, 'table'),
    [filterIncludes],
  );
  const columnHighlightFilter = useMemo(
    () => pickHighlightFilter(filterIncludes, 'column'),
    [filterIncludes],
  );
  const maxHeight = showDetails ? `${dragListHeight}px` : `100%`;
  const commonListProps = {
    isFiltering,
    selectedTable,
    pickedTables,
    schemaPicking,
    tableDraggable,
    maxHeight,
    renderTableHover,
    disabledTablesByID,
    passedToTableRow: {
      highlightFilter: tableHighlightFilter,
      onSetTable: handleSelectTable,
      onDoubleClickTable,
    },
  };

  const hasPinnedTable = Object.keys(tablesActuallyPinnedMap).length > 0;
  const hasFavoriteTable = unfilteredFavoriteTables.length > 0;

  // Pick heading for zero matches alert.
  let zeroMatchesHeading = 'Zero matching tables.';
  if (viewMode === 'pinned' && !hasPinnedTable) {
    zeroMatchesHeading = 'Zero pinned tables.';
  } else if (viewMode === 'favorites' && !hasFavoriteTable) {
    zeroMatchesHeading = 'Zero favorited tables.';
  }

  // Pick body contents for zero matches alert.
  let zeroMatchesBody = <p className="text-sm mb-0">Clear the filter to see results.</p>;
  if (viewMode === 'pinned' && !hasPinnedTable) {
    zeroMatchesBody = (
      <div>
        <ul className="text-sm mt-2 mb-0 mx-2 list-disc">
          <li>Mozart automatically pins tables used in the query editor.</li>
          <li>
            You can manually pin a table to this tab by switching to another tab, hovering over a table,
            and clicking on the pin button.
          </li>
        </ul>
      </div>
    );
  } else if (viewMode === 'favorites' && !hasFavoriteTable) {
    zeroMatchesBody = (
      <div>
        <ul className="text-sm mt-2 mb-0 mx-2 list-disc">
          <li>Some tables are so useful you want to use them over and over again.</li>
          <li>Click on the star next to your favorite tables and they'll show up here.</li>
        </ul>
      </div>
    );
  }

  const nameList = (
    <>
      {actualFilteredTableCount > 0 ? (
        <>
          {listType === 'schema' ? (
            <SchemaExpandoList
              filterIncludes={filterIncludes}
              filteredExpandoObjectMap={filteredSchemasMap}
              unfilteredExpandoObjectMap={unfilteredSchemasMap}
              objectsExpandedMap={schemasExpandedMap}
              onToggleSchema={handleToggleSchema}
              {...commonListProps}
            />
          ) : listType === 'tag' ? (
            <TagExpandoList
              filteredExpandoObjectMap={filteredTagsMap}
              unfilteredExpandoObjectMap={unfilteredTagsMap}
              objectsExpandedMap={tagsExpandedMap}
              onToggleTag={handleToggleTag}
              tags={tags}
              {...commonListProps}
            />
          ) : (
            <TableNameList filteredTables={filteredTables} {...commonListProps} />
          )}
        </>
      ) : (
        <div ref={zeroMatchingTablesRef} className="py-2">
          <Alert variant="no_results" className="f-col items-center p-2 m-0">
            <h6 className="text-lg">{zeroMatchesHeading}</h6>
            {zeroMatchesBody}
          </Alert>
        </div>
      )}
    </>
  );

  let minResizeHeight = MIN_NAME_LIST_HEIGHT;
  if (actualFilteredTableCount === 0) {
    if (zeroMatchingTablesRef.current) {
      minResizeHeight = zeroMatchingTablesRef.current.offsetHeight;
    } else {
      minResizeHeight = NO_TABLES_HEIGHT;
    }
  }

  return (
    <div className="w-full h-full bg-white">
      <LoadingPlaceholder loading={!loaded} error={anyError} spinnerMinHeight={`344px`}>
        <div className="tt-table-list">
          <div className="f-between flex-none">
            <TextInput
              ref={filterRef}
              name="tt-table-list-filter"
              placeholder="Search"
              value={filter}
              onChange={handleFilterChange}
              onBlur={reportOnBlur}
              maxLength={200}
            />
            {rightOfSearchWidget}
          </div>
          {!hideTabs && (
            <div className="w-full f-row-y-center flex-none">
              <ViewModeToggle
                mode={viewMode}
                showPinned={showPinned}
                onlyAutoPin={onlyAutoPin}
                setMode={setViewMode}
              />
              {(hasViews || hasEmptyTables) && (
                <div className="f-row-y-center w-[43px]">
                  <div className="w-[1px] h-[28px] bg-pri-gray-200"></div>
                  <TooltipTrigger tip="Table Explorer Options">
                    <IconButton
                      icon={showAdvancedOptions ? 'ChevronDoubleUp' : 'ChevronDoubleDown'}
                      variant="lightDullTransparent"
                      size="small"
                      onClick={handleToggleShowAdvancedOptions}
                      className="ml-2"
                    />
                  </TooltipTrigger>
                </div>
              )}
            </div>
          )}
          {showAdvancedOptions && (
            <div className="w-full p-2 mb-1 f-row-y-center flex-none flex-wrap gap-x-2 whitespace-nowrap overflow-hidden rounded bg-sec-blue-gray-100">
              <Checkbox
                name="showEmptyTablsCheckbox"
                label="Show Empty Tables"
                checked={!hideEmptyTables}
                variant="purple"
                onClick={handleToggleHideEmptyTables}
              />
              <Checkbox
                name="showViewsCheckbox"
                label="Show Views"
                checked={!hideViews}
                variant="purple"
                onClick={handleToggleHideViews}
              />
            </div>
          )}

          <div ref={listContainerRef} className="w-full h-full min-h-0 flex-auto">
            {showDetails ? (
              <>
                <Resizable
                  style={{ position: 'relative' }}
                  size={{
                    width: '100%',
                    height: dragListHeight,
                  }}
                  minWidth="100%"
                  maxWidth="100%"
                  minHeight={minResizeHeight}
                  maxHeight={maxResizeHeight}
                  enable={{
                    bottom: true,
                  }}
                  handleComponent={{
                    bottom: <DragBar />,
                  }}
                  handleStyles={{
                    bottom: {
                      width: '100%',
                      height: '24px',
                      left: '0',
                      bottom: '-25px',
                    },
                  }}
                  onResize={handleResize}
                >
                  {nameList}
                </Resizable>
                <div className="tt-table-list-drag-space">
                  <TooltipTrigger tip="Close Table Details">
                    <button onClick={handleDeselectTable} className="w-5 h-6 p-[4px 0px 4px 4px] z-[1]">
                      <X size="24" color="var(--pri-gray-400)" />
                    </button>
                  </TooltipTrigger>
                </div>
                <TableDetails
                  loading={columnsIsLoading}
                  error={columnsError}
                  table={selectedTable as AggTable}
                  columns={columnDetails}
                  columnSamples={columnSamples}
                  highlightFilter={columnHighlightFilter}
                  height={availableListHeight - DRAG_BAR_HEIGHT - dragListHeight}
                  onDoubleClickColumn={onDoubleClickColumn}
                  renderColumnHover={renderColumnHover}
                />
              </>
            ) : (
              <div className="w-full h-full">{nameList}</div>
            )}
          </div>
        </div>
      </LoadingPlaceholder>
    </div>
  );
});

export default TableExplorerBase;
