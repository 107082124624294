import { useMemo } from 'react';

import { AggTable } from 'api/tableAPI';
import TablePickerExplorer from 'components/inputs/basic/TablePicker/TablePickerExplorer/TablePickerExplorer';
import Modal from 'components/layouts/containers/modals/Modal/Modal';

import AIAssistantTablePickerPickedTables from './AIAssistantTablePickerPickedTables';

interface AIAssistantTablePickerModalProps {
  selectedTable: AggTable | null;
  onClose: () => void;
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>;
  onClearSelection: () => void;
  pickTable: (table: AggTable) => void;
  unpickTable: (table: AggTable) => void;
  pickedTables: Record<string, AggTable>;
  pickSchema: (schema: string) => void;
  unpickSchema: (schema: string) => void;
  pickedSchemas: Set<string>;
  schemaPickingEnabled: boolean;
}

const AIAssistantTablePickerModal = (props: AIAssistantTablePickerModalProps) => {
  const {
    selectedTable,
    onClose,
    setSelectedTable,
    pickTable,
    unpickTable,
    pickSchema,
    unpickSchema,
    onClearSelection,
    pickedTables,
    pickedSchemas,
    schemaPickingEnabled,
  } = props;

  const multiPick = useMemo(() => {
    return {
      pickedTables,
      pickedSchemas,
      onUnpickTable: unpickTable,
      onUnpickSchema: unpickSchema,
      onPickSchema: pickSchema,
      schemaPickingEnabled,
    };
  }, [pickedTables, pickedSchemas, unpickTable, unpickSchema, pickSchema, schemaPickingEnabled]);

  return (
    <Modal
      header={`Select tables ${
        schemaPickingEnabled ? 'and/or schemas ' : ''
      }for your request (optional)`}
      cancelButton
      onClose={onClose}
      containerClass="w-[700px] h-[90%]"
    >
      {Object.keys(pickedTables).length > 0 && (
        <AIAssistantTablePickerPickedTables
          pickedTables={pickedTables}
          unpickTable={unpickTable}
          onClearSelection={onClearSelection}
        />
      )}
      <TablePickerExplorer
        selectedTable={selectedTable}
        tableDraggable={false}
        tablesNamesUsedInSql={[]}
        setSelectedTable={setSelectedTable}
        multiPick={multiPick}
        onPickTable={pickTable}
        hideTabs
      />
    </Modal>
  );
};

export default AIAssistantTablePickerModal;
