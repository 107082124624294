/*
  This is a generic "'Folder-Like' Item Explorer" that renders a list of "folder-like" items
  that can be open or closed. The "folder-like" items could be database schemas or Mozart Data tags.
*/
import React, { useCallback } from 'react';

import { AggTable } from 'api/tableAPI';
import { useUserProfile } from 'context/AuthContext';
import { getSortedSchemaKeys } from 'pages/Warehouse/DatabaseSearch2/DatabaseSearch2';

import HoveredIndexWrapper, { PassedByHoveredIndexWrapperUser } from '../../HoveredIndexWrapper';
import { FilteredSchemaMap, SchemasExpandedMap } from '../../TableExplorerReducer';
import { DisabledTablesByTableID, PassedToTableRowProps } from '../../TableNameList';

import { TableExplorerCommonExpandoProps } from '../expandos/TableExplorerGenericExpando';

export interface RenderExpandoProps extends Omit<TableExplorerCommonExpandoProps, 'hideSchema'> {
  // Used by expando:
  key: string;
  picked: boolean;
  onPickSchema?(schemaKey: string): void;
  schemaPickingEnabled: boolean;
  companyName: string; // Needed to calculate schema icons due to Custom Connector hacks
}

interface PassedToInnerGenericExpandoListProps {
  isFiltering: boolean;
  filteredExpandoObjectMap: FilteredSchemaMap; // This could be any object type that fits this type (SchemaMap, TagMap)
  objectsExpandedMap: SchemasExpandedMap; // This could be any object type that fits this type (SchemaMap, TagMap)
  selectedTable: AggTable | null;
  pickedTables?: Record<string, AggTable>;
  schemaPicking?: {
    pickedSchemas: Set<string>;
    pickSchema(schemaKey: string): void;
    unpickSchema(schemaKey: string): void;
    enabled: boolean;
  };
  tableDraggable: boolean;
  disabledTablesByID: DisabledTablesByTableID;
  passedToTableRow: PassedToTableRowProps;
  renderExpando(props: RenderExpandoProps): void;
}

interface InnerGenericExpandoListProps extends PassedToInnerGenericExpandoListProps {
  unfilteredExpandoObjectMap: FilteredSchemaMap; // Same as filteredExpandoObjectMap, except unfiltered.
}

export interface GenericExpandoListProps
  extends InnerGenericExpandoListProps,
    PassedByHoveredIndexWrapperUser {}

const GenericExpandoList = React.memo((props: GenericExpandoListProps) => {
  const {
    filteredExpandoObjectMap,
    objectsExpandedMap,
    maxHeight,
    selectedTable,
    renderTableHover,
    ...rest
  } = props;

  const getHoveredTable = useCallback(
    (hoveredIndex: number) => {
      // Am I hovering over the TableExplorer at all?
      if (hoveredIndex < 0) {
        return null;
      }

      const sortedExpandoKeys = getSortedSchemaKeys(filteredExpandoObjectMap, []);

      for (const key of sortedExpandoKeys) {
        // Am I hovering over an expando heading, which is not a table?
        if (hoveredIndex <= 0) {
          return null;
        }
        // Subtract 1 for the heading
        hoveredIndex--;

        // Is the current expando open?
        if (objectsExpandedMap[key]) {
          const tables = filteredExpandoObjectMap[key];
          // Is the hovered row in the current expando?
          if (hoveredIndex < tables.length) {
            return tables[hoveredIndex];
          } else {
            hoveredIndex -= tables.length;
          }
        }
      }

      // I didn't find anything, so the cursor is probably below the expando list.
      return null;
    },
    [filteredExpandoObjectMap, objectsExpandedMap],
  );

  return (
    <HoveredIndexWrapper
      maxHeight={maxHeight}
      selectedTable={selectedTable}
      renderTableHover={renderTableHover}
      getHoveredTable={getHoveredTable}
      renderedTables={
        <InnerGenericExpandoList
          filteredExpandoObjectMap={filteredExpandoObjectMap}
          objectsExpandedMap={objectsExpandedMap}
          selectedTable={selectedTable}
          {...rest}
        />
      }
    />
  );
});

const InnerGenericExpandoList = React.memo((props: InnerGenericExpandoListProps) => {
  const {
    isFiltering,
    filteredExpandoObjectMap,
    unfilteredExpandoObjectMap,
    objectsExpandedMap,
    selectedTable,
    pickedTables,
    schemaPicking,
    tableDraggable,
    disabledTablesByID,
    passedToTableRow,
    renderExpando,
  } = props;
  const { userProfile } = useUserProfile();

  const sortedExpandoKeys = getSortedSchemaKeys(filteredExpandoObjectMap, []);

  return (
    <div className="tt-table-list-name-list">
      {sortedExpandoKeys.map((key: string, index: number) => {
        const isOpen = objectsExpandedMap[key];
        const tables = filteredExpandoObjectMap[key] || [];
        const unfilteredCount = unfilteredExpandoObjectMap[key].length;
        const picked = !!schemaPicking?.pickedSchemas?.has(key);

        const onPickSchema = picked ? schemaPicking?.unpickSchema : schemaPicking?.pickSchema;

        const renderExpandoProps: RenderExpandoProps = {
          key,
          picked,
          onPickSchema,
          schemaPickingEnabled: !!schemaPicking?.enabled,
          isOpen: isOpen,
          isFiltering,
          tables: tables,
          unfilteredCount,
          companyName: userProfile.company.name,
          selectedTable,
          pickedTables,
          tableDraggable,
          disabledTablesByID: disabledTablesByID,
          passedToTableRow: passedToTableRow,
        };

        return renderExpando(renderExpandoProps);
      })}
    </div>
  );
});

export default GenericExpandoList;
