import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { AggTable } from 'api/tableAPI';
import { useBooleanFlag } from 'hooks/useFeatureFlags';

export interface AIAssistantTablePickerState {
  selectedTable: AggTable | null;
  setSelectedTable: Dispatch<SetStateAction<AggTable | null>>;
  pickedTables: Record<string, AggTable>;
  pickedSchemas: Set<string>;
  pickTable: (table: AggTable) => void;
  unpickTable: (table: AggTable) => void;
  pickSchema: (schema: string) => void;
  unpickSchema: (schema: string) => void;
  enabled: boolean;
  schemaPickingEnabled: boolean;
  showModal: boolean;
  onOpen: () => void;
  onClearSelection: () => void;
  onClose: () => void;
}

export default function useAIAssistantTablePicker(): AIAssistantTablePickerState {
  const enabled = useBooleanFlag('ai_table_picker');
  const schemaPickingEnabled = useBooleanFlag('ai_table_picker_pick_schemas');
  // Single table that is clicked on to get metadata and sample data
  const [selectedTable, setSelectedTable] = useState<AggTable | null>(null);
  // Multiple tables that the user has deemed relevant
  // Key is table id
  const [pickedTables, setPickedTables] = useState<Record<string, AggTable>>({});
  // Multiple schema keys that the user has deemed relevent
  const [pickedSchemas, setPickedSchemas] = useState<Set<string>>(new Set());

  const [showModal, setShowModal] = useState(false);

  const toSegmentProperties = useCallback((table: AggTable) => {
    return { full_name: table.full_name, id: table.id };
  }, []);

  const onOpen = useCallback(() => {
    analytics.track('AIPipelineAssistant OpenTablePicker');
    setShowModal(true);
  }, []);

  const onClearSelection = useCallback(() => {
    analytics.track('AIPipelineAssistant ClearTablePickerSelection');
    setPickedTables({});
  }, []);

  const onClose = useCallback(() => {
    analytics.track('AIPipelineAssistant CloseTablePicker', {
      tables: Object.values(pickedTables).map(toSegmentProperties),
      table_count: Object.keys(pickedTables).length,
      schemas: Array.from(pickedSchemas),
      schema_count: pickedSchemas.size,
    });
    setShowModal(false);
  }, [pickedTables, pickedSchemas, toSegmentProperties]);

  const pickTable = useCallback(
    (table: AggTable) => {
      analytics.track('AIPipelineAssistant PickTable', toSegmentProperties(table));
      setPickedTables({ ...pickedTables, [table.id]: table });
    },
    [pickedTables, toSegmentProperties],
  );

  const unpickTable = useCallback(
    (table: AggTable) => {
      analytics.track('AIPipelineAssistant UnpickTable', toSegmentProperties(table));
      const newPickedTables = { ...pickedTables };
      delete newPickedTables[table.id];
      setPickedTables(newPickedTables);
    },
    [pickedTables, toSegmentProperties],
  );

  const pickSchema = useCallback(
    (schema: string) => {
      analytics.track('AIPipelineAssistant PickSchema', { schema });
      setPickedSchemas(new Set([...pickedSchemas, schema]));
    },
    [pickedSchemas],
  );

  const unpickSchema = useCallback(
    (schema: string) => {
      analytics.track('AIPipelineAssistant UnpickSchema', { schema });
      const newPickedSchemas = new Set(pickedSchemas);
      newPickedSchemas.delete(schema);
      setPickedSchemas(newPickedSchemas);
    },
    [pickedSchemas],
  );

  return {
    selectedTable,
    setSelectedTable,
    pickedTables,
    pickTable,
    unpickTable,
    pickedSchemas,
    pickSchema,
    unpickSchema,
    enabled,
    schemaPickingEnabled,
    showModal,
    onOpen,
    onClearSelection,
    onClose,
  };
}
