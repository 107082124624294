/**
 * Shared boiler plate for all of the modals that open when you double click
 * a Flowchart vertex.
 */
import Button from 'components/inputs/basic/Button/Button';
import Modal, { ModalProps } from 'components/layouts/containers/modals/Modal/Modal';

interface VertexModalProps extends Pick<ModalProps, 'children' | 'header' | 'containerClass'> {
  showFooter?: boolean;
  error?: string;
  onCancel: () => void;
  onSave?: () => void;
}

const VertexModal = (props: VertexModalProps) => {
  const { showFooter = true, error, onCancel, onSave, ...rest } = props;

  const modalFooter = (
    <div className="w-full">
      {error && <div className="f-row-y-center justify-start text-pri-error-700 mb-4">{error}</div>}
      <div className="f-row-y-center justify-end">
        <div className="f-row-y-center">
          <Button variant="lightDanger" onClick={onCancel} size="small">
            Cancel
          </Button>
          {onSave && (
            <Button variant="darkDullAction" className="ml-2" onClick={onSave} size="small">
              Apply
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <Modal onClose={onCancel} cancelButton={true} footer={showFooter ? modalFooter : null} {...rest} />
  );
};

export default VertexModal;
