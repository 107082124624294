/*
This is the TableExplorer used in form inputs.

Note that it memoizes everything to reduce the number of rerenders.
The TableExplorer component hierarchy is rather expensive.
*/
import React, { useCallback, useEffect, useMemo } from 'react';

import { AggTable } from 'api/APITypes';
import { Column } from 'api/columnAPI';
import TableExplorerBase from 'components/query/TableExplorer/TableExplorerBase';
import useTableExplorerReducer from 'components/query/TableExplorer/useTableExplorerReducer';

import TableRowHoverOverlay from './TableRowHoverOverlay';

interface TablePickerExplorerProps {
  selectedTable: AggTable | null;
  tableDraggable: boolean;
  tablesNamesUsedInSql: string[];
  hideTabs?: boolean;
  multiPick?: {
    pickedTables: Record<string, AggTable>;
    pickedSchemas: Set<string>;
    onUnpickTable(table: AggTable): void;
    onUnpickSchema(schema: string): void;
    onPickSchema(schema: string): void;
    schemaPickingEnabled: boolean;
  };
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>;
  onPickTable(table: AggTable): void;
  customTablesToDisableFilter?: (table: AggTable) => boolean;
}

const TablePickerExplorer = React.memo((props: TablePickerExplorerProps) => {
  const { tablesNamesUsedInSql, multiPick, onPickTable, customTablesToDisableFilter } = props;

  const tableExplorerReduction = useTableExplorerReducer(
    'TablePickerExplorer',
    customTablesToDisableFilter,
  );
  const { disabledTablesByID, setTableNamesInSql } = tableExplorerReduction;

  /*******************************************************************************
   * Overrides relevant to TablePicker
   ******************************************************************************/
  useEffect(() => {
    setTableNamesInSql(tablesNamesUsedInSql);
  }, [tablesNamesUsedInSql, setTableNamesInSql]);

  /*******************************************************************************
   * Overrides relevant to TableRows In TableList(s)
   ******************************************************************************/
  const handlePickTable = useCallback(
    (table: AggTable) => {
      if (multiPick?.pickedTables[table.id]) {
        analytics.track('TablePickerExplorer UnpickTable');
        return multiPick.onUnpickTable(table);
      } else {
        analytics.track('TablePickerExplorer PickTable');
        return onPickTable(table);
      }
    },
    [onPickTable, multiPick],
  );

  const handleDoubleClickTable = useCallback((table: AggTable) => {
    // Do nothing for now. Yay!
  }, []);

  const renderTableHover = useCallback(
    (
      hoveredIndex: number,
      hoveredTable: AggTable,
      selectedTable: AggTable | null,
      overlayRight: number,
    ) => {
      const rowDisabled = disabledTablesByID[hoveredTable.id] !== undefined;

      return (
        <TableRowHoverOverlay
          hoveredIndex={hoveredIndex}
          table={hoveredTable}
          selected={hoveredTable.id === selectedTable?.id}
          disabled={rowDisabled}
          picked={!!multiPick?.pickedTables[hoveredTable.id]}
          overlayRight={overlayRight}
          onPickTable={handlePickTable}
        />
      );
    },
    [disabledTablesByID, multiPick, handlePickTable],
  );

  /*******************************************************************************
   * Overrides relevant to ColumnRows in TableDetails
   ******************************************************************************/
  const renderColumnHover = useCallback((column: Column, overlayRight: number) => {
    // Do nothing for now. Yay!
    return null;
  }, []);

  const handleDoubleClickColumn = useCallback((column: Column) => {
    // Do nothing for now. Yay!
  }, []);

  const schemaPicking = useMemo(() => {
    if (!multiPick) return undefined;
    return {
      pickedSchemas: multiPick.pickedSchemas,
      pickSchema: multiPick.onPickSchema,
      unpickSchema: multiPick.onUnpickSchema,
      enabled: multiPick.schemaPickingEnabled,
    };
  }, [multiPick]);

  return (
    <TableExplorerBase
      showPinned={tablesNamesUsedInSql.length > 0}
      onlyAutoPin={true}
      pickedTables={multiPick?.pickedTables ?? {}}
      schemaPicking={schemaPicking}
      {...props}
      tableExplorerReduction={tableExplorerReduction}
      eventLocation="TablePickerExplorer"
      onDoubleClickTable={handleDoubleClickTable}
      onDoubleClickColumn={handleDoubleClickColumn}
      renderTableHover={renderTableHover}
      renderColumnHover={renderColumnHover}
    />
  );
});

export default TablePickerExplorer;
